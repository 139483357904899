<template>
    <div id="content" class="row primary-content">
        <div class="container">
            <div class="row">
                <div class=" col-md-10 col-lg-offset-1 col-lg-8 col-lg-offset-2">
                    <div class="section">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-xs-12">
                                    <h2>Make a Payment</h2>
                                </div>
                            </div>



                            <div class="row payment-method-list">
                                <div class="col-xs-12">
                                    <h4>1. Invoice Summary</h4>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-6 col-sm-6">
                                    Invoice #{{invoice.number}}
                                </div>
                                <div class="col-xs-6 col-sm-6 text-right">
                                    {{balanceDue | formatMoney}}
                                </div>
                            </div>

                            <br/>

                            <div class="row payment-method-list">
                                <div class="col-xs-12">
                                    <h4>2. Payment Method</h4>
                                </div>
                            </div>

                            <div class="row" v-if="paymentMethods.length">
                                <div class="col-xs-12 col-sm-6" v-if="paymentMethods[0].type == 'card'">
                                    <span class="cc-icon-holder">
                                        <img class="cc-icon" :src="paymentMethods[0] | getCCIcon" />
                                        <span v-if="paymentMethods[0].auto_charge" class="autopay-icon">
                                            <i class="fa fa-check-circle"></i>&nbsp;AutoPay
                                        </span>
                                    </span>

                                    <span class="cc-method-col">
                                        <span class="card-type">{{paymentMethods[0].card_type}} *{{paymentMethods[0].card_end}}</span>
                                        <br />
                                        <span class="card-detail">{{paymentMethods[0].first}} {{paymentMethods[0].last}}</span>
                                    </span>
                                </div>
                                <div class="col-xs-12 col-sm-6" v-else-if="paymentMethods[0].type == 'ach'">
                                    <img class="cc-icon" :src="'/img/cc_icons/64/ach.png'" />
                                    <span class="cc-method-col">
                                        {{paymentMethods[0].card_type}} *{{paymentMethods[0].card_end}}<br />
                                        <span class="card-detail">{{paymentMethods[0].first}} {{paymentMethods[0].last}}</span>
                                    </span>
                                </div>
                            </div>


                            <div class="row lined" v-for="method in paymentMethods" v-if="chooseMethod == true && (method.type == 'card' || method.type == 'ach')">
                                <div class="col-xs-12 col-sm-6" v-if="method.type == 'card'">
                                    <span class="cc-icon-holder">
                                        <img class="cc-icon" :src="method | getCCIcon" />
                                        <span v-if="method.auto_charge" class="autopay-icon">
                                            <i class="fa fa-check-circle"></i>&nbsp;AutoPay
                                        </span>
                                    </span>

                                    <span class="cc-method-col">
                                        <span class="card-type">{{method.card_type}} *{{method.card_end}}</span>
                                        <br />
                                        <span class="card-detail">{{method.first}} {{method.last}}</span>
                                    </span>
                                </div>
                                <div class="col-xs-12 col-sm-6" v-else-if="method.type == 'ach'">
                                    <img class="cc-icon" :src="'/img/cc_icons/64/ach.png'" />
                                    <span class="cc-method-col">
                                        {{method.card_type}} *{{method.card_end}}<br />
                                        <span class="card-detail">{{method.first}} {{method.last}}</span>
                                    </span>
                                </div>
                                <div class="col-xs-12 col-sm-6 text-right payment-choose">
                                    <button class="btn btn-dark">Use This Method</button>
                                </div>
                            </div>

                            <div class="row" v-for="method in paymentMethods" v-if="chooseMethod == true">
                                <div class="col-xs-12">
                                    <a @click="showAddPaymentMethodModal=true" class="btn btn-clear" >Add A New Payment Method</a>
                                </div>
                            </div>



                            <div class="row footer text-center">
                                <div class="col-xs-12 ">
                                   <button class="btn btn-dark btn-full">Pay {{balanceDue | formatMoney}} now</button>
                                </div>
                                <p class="subdued">Your credit card will be charged</p>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal v-if="showAddPaymentMethodModal" size="md" allow-click-away="true" @close="showAddPaymentMethodModal = false">
            <h3 slot="header">Add Payment Method</h3>
            <add-payment-method  slot="body" :lease_id="invoice.lease_id" @close="showAddPaymentMethodModal = false" @refetch="fetchPaymentMethods">
            </add-payment-method>
        </modal>
    </div>


</template>

<script type="text/babel">

    import Modal from '../assets/Modal.vue';
    import AddPaymentMethod from '../leases/AddPaymentMethod.vue';

    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';


    export default {
        data() {
            return {
                invoice:{},
                payments:[],
                paymentMethods:[],
                showAddPaymentMethodModal: false,
                chooseMethod: false,
            }
        },
        components:{
            Modal,

            AddPaymentMethod
        },
        created (){
            this.fetchData();
            this.fetchPayments();
        },
        filters:{

            lineTotal(c){
                return c.qty * c.cost;
            }
        },
        computed:{
            isPastDue(){

                return this.balanceDue && moment() > moment(this.invoice.due, 'MM/DD/YYYY');
            },
            paymentMethodNames(){
              return this.paymentMethods.map(function(pm){
                return pm.name_on_card;
              })
            },
            balanceDue(){
                return this.invoiceTotal - this.totalPayments;
            },
            totalPayments(){
                return this.payments.reduce(function(a, b){
                    return a + b.amount;
                }, 0)
            },
            promotionTotal(){
                var discountTotal = 0;
                if(!this.invoice.InvoiceLines) return 0;
                this.invoice.InvoiceLines.forEach(function(line){
                    discountTotal += line.DiscountLines.reduce(function(a,b){
                        return a += b.amount;
                    }, 0);
                });
                return discountTotal;
            },
            taxSum(){
                var _this = this;
                if(!this.invoice.InvoiceLines) return 0;
                return this.invoice.InvoiceLines.reduce(function(a, b){
                    var lineTotal = (b.cost * b.qty);
                    if(!b.Product.taxable) return a;
                    var notTaxed = b.DiscountLines.filter(function(d){
                        return d.pretax;
                    }).reduce(function(c,d){
                        return c + d.amount;
                    }, 0);
                    var taxableAmount = lineTotal - notTaxed;
                    var lineTax =  b.TaxLines.reduce(function(c,d){
                        return c +=  (taxableAmount *  (d.taxrate/100))
                    }, 0);
                    return a += lineTax;

                }, 0)
            },
            subTotal(){
                if(!this.invoice.InvoiceLines) return 0;
                return  this.invoice.InvoiceLines.reduce(function(a, b){
                    return a +=  (b.cost * b.qty)
                }, 0);
            },
            invoiceTotal(){
                return this.taxSum + this.subTotal;
            }
        },
        methods: {
            payInvoice(){
                this.$router.push('/invoices/pay/'+ this.$route.params.id)
            },
            closeDropdown(){
                //TODO JQUERY FIX
                //$(event.target).closest('.dropdown-menu').dropdown('toggle');
            },
            prevent(event){
                event.stopPropagation();
            },
            fetchData(){
                api.get(this, api.INVOICES + this.$route.params.id ).then(r => {
                    this.invoice = r.invoice;
                    this.invoice.date = moment(this.invoice.date).format('MM/DD/YYYY');
                    this.invoice.due = moment(this.invoice.due).format('MM/DD/YYYY');
                    this.fetchPaymentMethods();
                });
            },
            fetchPayments(){
                api.get(this, api.INVOICES + this.$route.params.id +'/payments' ).then(r => {
                    this.payments = r.payments;
                    this.payments.date = moment(this.payments.date).format('MM/DD/YYYY');
                });

            },
            fetchPaymentMethods(){
                api.get(this, api.LEASES + this.$route.params.lease_id + '/payment-methods').then(r => {
                    this.paymentMethods = r.paymentMethods;
                });
            },
            away(){
                return this.openSuggestion = false;
            }
        }
    }

</script>

<style scoped>
    .payment-method-list{
        margin-top: 5px;
    }
    .payment-choose{
        padding-top: 20px;
    }
    .cc-icon{
        float: left
    }
    .footer{
        margin-top: 50px;
    }
    h4{
        margin-bottom: 0;
    }
    </style>
